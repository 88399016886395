.HeroCarousel_wrapper__1oc1h {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.HeroCarousel_slide__2kBJV {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  transition: opacity 1s ease;
}

.HeroCarousel_slide__2kBJV.HeroCarousel_active__3O2Ci {
  z-index: 1;
  opacity: 1;
}

